import React from "react";
import { Modal } from "react-bootstrap";
import "./placebet.scoped.css";
import { getProfit } from "../../utils/constants";
export default function PlaceBetModal({
  isOpen,
  onClose,
  selectedEventForBet,
  setSelectedEventForBet,
  handleDefaultValueClick,
  handlePlaceBet,
}) {
     const colorDecided = selectedEventForBet?.isFancy
       ? selectedEventForBet?.type == "lay"
         ? "lay"
         : "back"
       : selectedEventForBet?.type == "lay"
       ? "back"
       : "lay";

     return (
       <Modal show={isOpen} centered onClose={onClose}>
         <div
           type={selectedEventForBet?.type}
           className={`sc-itybZL gSMpdg ${colorDecided}`}
         >
           <div
             type={selectedEventForBet?.type}
             className={`sc-dTdPqK leygRy  ${colorDecided}`}
           >
             <div className="sc-krDsej hYVmcl">
               {selectedEventForBet?.runnerName}
               <div className="sc-bHwgHz cHDyJQ">
                 {selectedEventForBet?.eventName}
               </div>
             </div>
             <div className="sc-jVODtj flGjPM">
               <div className="sc-gPWkxV dRZyjJ">MAX BET:10000</div>
               <div className="sc-gPWkxV dRZyjJ">MAX MKT:100</div>
             </div>
           </div>
           <div className="sc-giadOv jabHrc">
             <div className="sc-fjmCvl cQbrHN">
               <div className="sc-fONwsr VucgM">
                 <label>Odds</label>
                 <div className="sc-jzJRlG cTudZX">
                   <button tabIndex={-1} className="sc-fjdhpX eFBOjU">
                     -
                   </button>
                   <input
                     type="number"
                     step="any"
                     className="sc-jTzLTM derQBl"
                     value={selectedEventForBet?.selectedOdd}
                   />
                   <button tabIndex={-1} className="sc-fjdhpX eFBOjU">
                     +
                   </button>
                 </div>
               </div>
               <div className="sc-fONwsr VucgM">
                 <label>Stake</label>
                 <input
                   placeholder="Min : 100"
                   type="number"
                   pattern="\d*"
                   step="any"
                   value={selectedEventForBet?.amount}
                   className="sc-laTMn ldfEmf"
                   defaultValue=""
                 />
               </div>
               <div
                 type={selectedEventForBet?.type}
                 className={`sc-TFwJa OtzUb ${colorDecided}`}
               >
                 <span className="sc-eMigcr bzYAQg">Est.Profit</span>
                 <span
                   style={{
                     color:
                       selectedEventForBet?.profit == 0 ? "red" : "green",
                   }}
                   className="sc-hmzhuo cSXNXx"
                 >
                   {selectedEventForBet?.profit}
                 </span>
               </div>
             </div>
             <div className="sc-fjmCvl cQbrHN">
               <div className="sc-gJWqzi gFNUZF">
                 {[100, 500, 1000, 5000, 10000, 25000, 50000].map((item) => (
                   <div className="sc-jzgbtB gavpov">
                     <button
                       className="sc-fhYwyz cFNLpn"
                       onClick={() => {
                         handleDefaultValueClick(item);
                       }}
                     >
                       {item}
                     </button>
                   </div>
                 ))}
               </div>
             </div>
           </div>
           <div className="sc-VJcYb ekmHYF">
             <div className="sc-hGoxap lnRPqv">
               <button
                 onClick={onClose}
                 id="gtm-cancel_order_4538722_7862023_BACK"
                 className="sc-rBLzX sc-bMvGRv dpFnVx"
               >
                 Cancel Order
               </button>
             </div>
             <div className="sc-hGoxap lnRPqv">
               <button
                 onClick={handlePlaceBet}
                 style={{
                   backgroundColor: "var(--secondary)",
                 }}
                 id="gtm-place_order_4538722_7862023_LAY"
                 className="sc-rBLzX sc-CtfFt eOhnxP"
               >
                 Place Bet
               </button>
             </div>
           </div>
         </div>
       </Modal>
     );
   }
