import React, { useEffect } from "react";
import Header from "./components/Header";

import { Link, useLocation, useNavigate } from "react-router-dom";
import SvgComponent from "./components/SvgComponent";
import { useDispatch, useSelector } from "react-redux";

import {
  getAllMatchesOddsCricketAPI,
  getBetCountAPI,
} from "../service/cricket";
import moment from "moment";
import MatkaDashboard from "./matka/MatkaDashboard";
import { getAllSoccerMatchListAPI } from "../service/soccer&tennis";

const Matches = ({ isUpcoming }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [selectedGame, setSelectedGame] = React.useState(
    location.pathname.includes("casino") ? "casino" : "cricket"
  );

  const userData = useSelector((state) => state.account.userData);

  const cricketData = useSelector((state) =>
    state?.gameData?.cricketGames
      ?.filter((item) => {
        if (isUpcoming) {
          return !item?.inPlay;
        } else {
          return true;
        }
      })
      .map((item) => {
        return {
          ...item,
          sport: "cricket",
        };
      })
  );

  const naviagte = useNavigate();

  const getCricketMatches = async () => {
    try {
      const { response, code } = await getAllMatchesOddsCricketAPI();
      const data = await getAllSoccerMatchListAPI();
      console.log(data);
      if (code == 200) {
        let counts = await getBetCount();
        response.map((item) => {
          let countData =
            counts?.betCount[
              Object.keys(counts.betCount).find(
                (count) => count == item?.cricketId
              )
            ];

          item.matchCount = countData?.matchCount;
          item.fancyCount = countData?.fancyCount;
          return item;
        });

        dispatch({
          type: "gameData/setCricketGames",
          payload: response,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    let interval;
    getCricketMatches();
    if (selectedGame == "cricket") {
      getCricketMatches();
      interval = setInterval(() => {
        getCricketMatches();
      }, 5000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [selectedGame]);
  const navigateToGame = (gameEvent) => {
    if (!userData?.token) {
      dispatch({
        type: "accountData/setLoginPopSate",
        payload: true,
      });
      return;
    }

    if (selectedGame == "all") {
      naviagte(
        `/game/${gameEvent?.sport}/${gameEvent[gameEvent?.sport + "Id"]}`,
        {
          state: { gameEvent },
        }
      );
    } else {
      naviagte(`/game/${selectedGame}/${gameEvent[selectedGame + "Id"]}`, {
        state: { gameEvent },
      });
    }
  };

  const getBetCount = async () => {
    const { response } = await getBetCountAPI();
    return response;
  };

  return (
    <>
      <div className="position-relative">
        <main className="warp">
          <ul className="games-types-top">
            <li
              style={{ width: "50%" }}
              onClick={() => setSelectedGame("cricket")}
              className={`cricket-button ${
                selectedGame == "cricket" ? "active" : ""
              }`}
            >
              <i class="far fa-cricket fa-fw" />
              <span>Cricket</span>
            </li>
            <li
              style={{ width: "50%" }}
              onClick={() => setSelectedGame("casino")}
              className={`Casino ${selectedGame == "casino" ? "active" : ""}`}
            >
              <i class="fas fa-spade" />
              <span>Casino</span>
            </li>

            {/*  <li
              onClick={() => setSelectedGame("matka")}
              className={`Matka ${selectedGame == "matka" ? "active" : ""}`}
            >
              <i class="fas fa-cauldron" />
              <span>Matka</span>
            </li> */}
          </ul>

          {selectedGame == "cricket" ||
          selectedGame === "soccer" ||
          selectedGame == "tennis" ? (
            <>
              {cricketData?.filter((item) => item?.inPlay).length > 0 && (
                <Link className="btn btn-primary backbutton w-100">
                  Live Games
                </Link>
              )}
              <div>
                {cricketData
                  ?.filter((item) => item?.inPlay)
                  ?.map((gameEvent) => (
                    <div
                      className={`old-matches-list ${
                        isUpcoming ? "" : "live-match"
                      }`}
                    >
                      <div className="list-matches">
                        <div onClick={() => navigateToGame(gameEvent)}>
                          <h6>
                            {selectedGame == "cricket" ? (
                              <i class="far fa-cricket fa-fw blink" />
                            ) : selectedGame == "soccer" ? (
                              <i class="far fa-futbol fa-fw blink" />
                            ) : selectedGame == "tennis" ? (
                              <i class="far fa-tennis-ball fa-fw blink" />
                            ) : null}
                            <span className="d-inline-flex align-items-center float-left mx-2">
                              <div className="blink" />
                            </span>
                            {gameEvent.eventName}
                          </h6>
                          <h5>
                            {moment(
                              gameEvent.eventTime
                                ? gameEvent.eventTime
                                : gameEvent.eventDate
                            ).format("DD MMM hh:mm A")}
                          </h5>
                          <div className="match-info">
                            <p>
                              Match Bets :{" "}
                              {gameEvent?.matchCount
                                ? gameEvent?.matchCount
                                : 0}
                            </p>
                            <p>
                              Session Bets :{" "}
                              {gameEvent?.fancyCount
                                ? gameEvent?.fancyCount
                                : 0}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>

              {cricketData?.length > 0 && (
                <Link className="btn btn-primary backbutton w-100">
                  Upcoming Games
                </Link>
              )}
              {!isUpcoming && (
                <div>
                  {cricketData
                    ?.filter((item) => !item?.inPlay)
                    .map((gameEvent) => (
                      <div
                        className={`old-matches-list ${
                          isUpcoming ? "" : "live-match"
                        }`}
                      >
                        <div className="list-matches">
                          <div onClick={() => navigateToGame(gameEvent)}>
                            <h6>
                              {selectedGame == "cricket" ? (
                                <i class="far fa-cricket fa-fw blink" />
                              ) : selectedGame == "soccer" ? (
                                <i class="far fa-futbol fa-fw blink" />
                              ) : selectedGame == "tennis" ? (
                                <i class="far fa-tennis-ball fa-fw blink" />
                              ) : null}
                              <span className="d-inline-flex align-items-center float-left mx-2">
                                <div className="blink" />
                              </span>
                              {gameEvent.eventName}
                            </h6>
                            <h5>
                              {moment(
                                gameEvent.eventTime
                                  ? gameEvent.eventTime
                                  : gameEvent.eventDate
                              ).format("DD MMM hh:mm A")}
                            </h5>
                            <div className="match-info">
                              <p>
                                Match Bets :{" "}
                                {gameEvent?.matchCount
                                  ? gameEvent?.matchCount
                                  : 0}
                              </p>
                              <p>
                                Session Bets :{" "}
                                {gameEvent?.fancyCount
                                  ? gameEvent?.fancyCount
                                  : 0}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </>
          ) : null}

          {selectedGame == "casino" ? (
            <>
              <div className="container-fluid" style={{ padding: "20px 27px" }}>
                <div className="row">
                  {/*  <div
                    className="col-lg-3 col-md-3 col-sm-3 col-xs-6"
                    style={{
                      marginBottom: 5,
                      paddingLeft: 0,
                      paddingRight: 0,
                    }}
                  >
                    <Link to="/casino/ander-bahar">
                      <div
                        className="hover ehover4"
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          className="img-responsive"
                          src="assets/images/ab20.jpg"
                          alt="king"
                          style={{ width: "100%" }}
                        />
                        <p
                          style={{
                            margin: 0,
                            padding: 5,
                            background:
                              "linear-gradient(45deg, #720c48, #f51249)",
                            color: "#fff",
                            textAlign: "center",
                          }}
                        >
                          Andar Bahar
                        </p>
                      </div>
                    </Link>
                  </div> */}

                  <div
                    className="col-lg-3 col-md-3 col-sm-3 col-xs-6"
                    style={{
                      marginBottom: 5,
                      paddingLeft: 0,
                      paddingRight: 0,
                    }}
                  >
                    <Link to="/casino/live-teen20">
                      <div
                        className="hover ehover4"
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          className="img-responsive"
                          src="/assets/images/teen20.jpg"
                          alt="king"
                          style={{ width: "100%" }}
                        />
                        <p
                          style={{
                            margin: 0,
                            padding: 5,
                            backgroundColor: "var(--secondary)",
                            color: "#fff",
                            textAlign: "center",
                          }}
                        >
                          Teen Patti T20
                        </p>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col-lg-3 col-md-3 col-sm-3 col-xs-6"
                    style={{
                      marginBottom: 5,
                      paddingLeft: 0,
                      paddingRight: 0,
                    }}
                  >
                    <Link to="/casino/lucky7">
                      <div
                        className="hover ehover4"
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          className="img-responsive"
                          src="/assets/images/lucky7.jpg"
                          alt="king"
                          style={{ width: "100%" }}
                        />
                        <p
                          style={{
                            margin: 0,
                            padding: 5,
                            backgroundColor: "var(--secondary)",
                            color: "#fff",
                            textAlign: "center",
                          }}
                        >
                          Lucky 7A
                        </p>
                      </div>
                    </Link>
                  </div>
                  <div
                    className="col-lg-3 col-md-3 col-sm-3 col-xs-6"
                    style={{
                      marginBottom: 5,
                      paddingLeft: 0,
                      paddingRight: 0,
                    }}
                  >
                    <Link to="/casino/dragon-tiger">
                      <div
                        className="hover ehover4"
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          className="img-responsive"
                          src="/assets/images/dt20.jpg"
                          alt="king"
                          style={{ width: "100%" }}
                        />
                        <p
                          style={{
                            margin: 0,
                            padding: 5,
                            backgroundColor: "var(--secondary)",
                            color: "#fff",
                            textAlign: "center",
                          }}
                        >
                          Dragon Tiger T20
                        </p>
                      </div>
                    </Link>
                  </div>
                  <div
                    className="col-lg-3 col-md-3 col-sm-3 col-xs-6"
                    style={{
                      marginBottom: 5,
                      paddingLeft: 0,
                      paddingRight: 0,
                    }}
                  >
                    <Link to="/casino/ander-bahar">
                      <div
                        className="hover ehover4"
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          className="img-responsive"
                          src="https://dataobj.ecoassetsservice.com/casino-icons/lc/abj.jpg"
                          alt="king"
                          style={{ width: "100%" }}
                        />
                        <p
                          style={{
                            margin: 0,
                            padding: 5,
                            backgroundColor: "var(--secondary)",
                            color: "#fff",
                            textAlign: "center",
                          }}
                        >
                          Andar Bahar
                        </p>
                      </div>
                    </Link>
                  </div>
                  {/* <div
                    className="col-lg-3 col-md-3 col-sm-3 col-xs-6"
                    style={{
                      marginBottom: 5,
                      paddingLeft: 0,
                      paddingRight: 0,
                    }}
                  >
                    <Link to="#">
                      <div
                        className="hover ehover4"
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          className="img-responsive"
                          src="assets/images/amar.jpeg"
                          alt="king"
                          style={{ width: "100%" }}
                        />
                        <p
                          style={{
                            margin: 0,
                            padding: 5,
                            background:
                              "linear-gradient(45deg, #720c48, #f51249)",
                            color: "#fff",
                            textAlign: "center",
                          }}
                        >
                          Amar Akbar Anthony
                        </p>
                      </div>
                    </Link>
                  </div> */}
                </div>
              </div>
            </>
          ) : null}
          {/* {selectedGame == "matka" ? <MatkaDashboard /> : null} */}
        </main>
      </div>
    </>
  );
};

export default Matches;
