import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { dashboardBannerAPI } from "../service/AuthService";
import Matches from "./Matches";

export default function Dashboard() {
  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const [homeImages, setHomeImages] = React.useState([]);
  const getBannerImages = async () => {
    var { response, code } = await dashboardBannerAPI();
    if (code === 200) {
      setHomeImages(response);
    }
  };
  useEffect(() => {
    getBannerImages();
  }, []);
  return (
    <>
      <div className="position-relative">
        <div className="slider-bottom" />
        <div className="container-fluid p-0">
          <Matches />
        </div>
      </div>
    </>
  );
}
