import React from "react";

export default function Admin() {
  return (
    <div>
      <iframe
        src="https://admin.shakti11.com"
        style={{
          width: "100%",
          height: "100vh",
        }}
      />
    </div>
  );
}
