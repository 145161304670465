import React from "react";
import { useEffect } from "react";

import { searchSubstringInArrayObjects } from "../utils/constants";
import { useDispatch } from "react-redux";

import moment from "moment";

import {
  getAccountStatementAPI,
  getBetsFromAccountLogs,
} from "../service/AuthService";
import { Link, useNavigate } from "react-router-dom";
import { Box, Flex } from "@chakra-ui/react";
import CustomInput from "./components/CustomInput";
import CustomSelect from "./components/CustomSelect";
import { Button } from "react-bootstrap";

export default function Ledger() {
  const [data, setData] = React.useState([]);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [currentPageData, setCurrentPageData] = React.useState([]);
  const navigate = useNavigate();
  const [type, setType] = React.useState("ALL");
  const dispatch = useDispatch();
  const [startDate, setStartDate] = React.useState(
    moment().subtract(7, "days").format("YYYY-MM-DD")
  );
  const [loading, setLoading] = React.useState(false);
  const [noOfPages, setNoOfPages] = React.useState(0);
  const [endDate, setEndDate] = React.useState(moment().format("YYYY-MM-DD"));
  const [selectedType, setSelectedType] = React.useState("ALL");

  const getAccountStatement = async () => {
    setLoading(true);
    let payload = {
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      type: type,
    };
    const { response } = await getAccountStatementAPI(payload);
    setNoOfPages(Math.ceil(response.length / rowsPerPage));
    setCurrentPageData(response.slice(0, rowsPerPage));

    setData(response);
    setLoading(false);
  };

  useEffect(() => {
    getAccountStatement();
  }, [type, startDate, endDate]);
  useEffect(() => {
    setCurrentPageData(
      data.slice(
        pageNumber * rowsPerPage,
        pageNumber * rowsPerPage + rowsPerPage
      )
    );
  }, [pageNumber, selectedType]);
  const handleSubmit = async () => {
    getAccountStatement();
  };
  const isMobileView = window.innerWidth < 768;
  return (
    <div className="position-relative">
      {loading ? (
        <>
          <div className="position-fixed w-100 h-100 d-flex align-items-center justify-content-center loader">
            <img src="/logo.png" alt="Loader" class="flip-vertical-right" />
          </div>
        </>
      ) : null}
      <Link
        style={{
          marginTop: "10px",
          marginBottom: "10px",
        }}
        className="btn btn-primary backbutton w-100"
        to="/dashboard"
      >
        Back to Main Menu
      </Link>
      <Box
        w="100%"
        display="flex"
        alignItems="center"
        justifyContent={{
          base: "space-between",
          md: "flex-start",
        }}
      >
        <Box
          flexDirection="column"
          display="flex"
          justifyContent="center"
          alignItems="center"
          my="2"
          width={"100%"}
        >
          <Flex width="100%" alignItems="center">
            <CustomInput
              placeholder="Select Start Date"
              type="date"
              bg="transparent"
              className="form-control"
              _placeholder={{ color: "gray" }}
              _focus={{ outline: "none" }}
              onChange={(e) => setStartDate(e.target.value)}
              value={startDate}
              containerStyle={{
                width: "50%",
                marginRight: "10px",
              }}
              textColor="#AAA391"
              size="md"
              borderRadius="5"
            />
            <CustomInput
              className="form-control"
              placeholder="Select Start Date"
              type="date"
              bg="transparent"
              borderRadius="5"
              _placeholder={{ color: "gray" }}
              _focus={{ outline: "none" }}
              onChange={(e) => setEndDate(e.target.value)}
              value={endDate}
              textColor="#AAA391"
              containerStyle={{
                width: "50%",
              }}
              size="md"
            />
          </Flex>
          <CustomSelect
            borderRadius="5"
            textColor="#AAA391"
            className="form-control"
            containerStyle={{
              margin: 5,
              width: isMobileView ? "100%" : "40rem",
            }}
            value={rowsPerPage}
            size="md"
            borderColor="#AAA391"
            onChange={(e) => setRowsPerPage(e.target.value)}
            options={[
              {
                label: "10",
                value: 10,
              },
              {
                label: "25",
                value: 25,
              },
              {
                label: "50",
                value: 50,
              },
              {
                label: "100",
                value: 100,
              },
            ]}
          />
          <Button
            onClick={handleSubmit}
            style={{ width: "100%", marginTop: "10px" }}
            className="btn btn-primary"
          >
            Submit
          </Button>
        </Box>
      </Box>
      <main className="warp table-responsive statement-tables">
        <table className="table-bordered text-center w-100 my-2">
          <tbody>
            <tr
              style={{
                color: "white",
                fontWeight: "bold",
                fontSize: "15px",
                background: "#fff",
              }}
            >
              <td colspan="5">MY LEDGER</td>
            </tr>
            <tr>
              <td
                width="40%"
                height="35"
                align="center"
                style={{ verticalAlign: "middle" }}
                bgcolor="#fff"
                className="FontTextWhite10px cell-td pdr-4"
              >
                MATCH NAME
              </td>
              <td
                width="15%"
                align="center"
                style={{ verticalAlign: "middle" }}
                bgcolor="#fff"
                className="FontTextWhite10px cell-td pdr-4"
              >
                Won By
              </td>
              <td
                width="15%"
                align="center"
                style={{ verticalAlign: "middle" }}
                bgcolor="#fff"
                className="FontTextWhite10px cell-td"
              >
                Won
              </td>
              <td
                width="15%"
                align="center"
                style={{ verticalAlign: "middle" }}
                bgcolor="#fff"
                className="FontTextWhite10px cell-td"
              >
                LOST
              </td>
              <td
                width="15%"
                align="center"
                style={{ verticalAlign: "middle" }}
                bgcolor="#fff"
                className="FontTextWhite10px cell-td"
              >
                Balance
              </td>
            </tr>
            {currentPageData
              ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
              ?.map((item) => (
                <tr>
                  <td
                    style={{
                      cursor: "pointer",
                      color: "blue",
                      textDecoration: "underline",
                    }}
                    onClick={() =>
                      item?.sport == "CRICKET"
                        ? navigate(`/complete_detail/${item?.accountLogId}`, {
                            state: {
                              item,
                            },
                          })
                        : navigate(`/casino/casino-bets`)
                    }
                  >
                    {item?.comment}{" "}
                    {moment(item?.createdAt + "Z").format("DD/MM/YYYY")}{" "}
                  </td>
                  <td>{item?.wonBy}</td>
                  <td
                    style={{
                      color: item?.creditInfo < 0 ? "red" : "green",
                    }}
                  >
                    {item?.creditInfo}
                  </td>
                  <td
                    style={{
                      color: item?.debitInfo < 0 ? "red" : "green",
                    }}
                  >
                    {item?.debitInfo}
                  </td>
                  <td>{parseFloat(item?.updatedBalance).toFixed(2)}</td>
                </tr>
              ))}
          </tbody>
        </table>
        <ul className="pagination justify-content-center mt-2 snipcss-ZlA51">
          <li
            onClick={() => {
              if (pageNumber > 0) {
                setPageNumber(0);
              }
            }}
            className="page-item "
          >
            <a tabIndex={-1} className="page-link">
              &lt;&lt;
            </a>
          </li>
          <li
            onClick={() => {
              if (pageNumber > 0) {
                setPageNumber(pageNumber - 1);
              }
            }}
            className="page-item "
          >
            <a tabIndex={-1} className="page-link">
              &lt;
            </a>
          </li>
          {Array.from({ length: noOfPages }, (_, i) => i + 1).map(
            (item, index) => (
              <li
                onClick={() => {
                  setPageNumber(index);
                }}
                className={`page-item ${index == pageNumber ? "active" : ""}`}
              >
                <a tabIndex={0} className="page-link">
                  {index + 1}
                </a>
              </li>
            )
          )}
          <li
            onClick={() => {
              if (pageNumber < noOfPages - 1) {
                setPageNumber(pageNumber + 1);
              }
            }}
            className="page-item "
          >
            <a tabIndex={-1} className="page-link">
              &gt;
            </a>
          </li>
          <li
            onClick={() => {
              if (pageNumber < noOfPages - 1) {
                setPageNumber(noOfPages - 1);
              }
            }}
            className="page-item "
          >
            <a tabIndex={-1} className="page-link">
              &gt;&gt;
            </a>
          </li>
        </ul>
      </main>
    </div>
  );
}
