import {
  Box,
  Button,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Select,
  Td,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { useEffect } from "react";
import CustomInput from "./components/CustomInput";
import CustomSelect from "./components/CustomSelect";
import CustomTable from "./components/Table";

import {
  bgColor,
  buttonColors,
  convertEpochToDate,
  getCurrentDateTimeLocal,
  searchSubstringInArrayObjects,
} from "../utils/constants";
import { SearchIcon } from "@chakra-ui/icons";
import { useDispatch } from "react-redux";

import moment from "moment";
//   import "../assets/pagination.css";
import {
  getAccountStatementAPI,
  getBetsFromAccountLogs,
  getCoinHistoryAPI,
} from "../service/AuthService";
export default function AccountStatement() {
  const [data, setData] = React.useState([]);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [currentPageData, setCurrentPageData] = React.useState([]);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [type, setType] = React.useState("ALL");
  const dispatch = useDispatch();
  const [startDate, setStartDate] = React.useState(
    moment()
      .subtract(7, "days")
      .format("YYYY-MM-DD")
  );
  const [noOfPages, setNoOfPages] = React.useState(0);
  const [endDate, setEndDate] = React.useState(moment().format("YYYY-MM-DD"));
  const [selectedType, setSelectedType] = React.useState("ALL");
  const [logData, setLogData] = React.useState([]);
  const [selectedLogId, setSelectedLogId] = React.useState(null);
  const isMobileView = window.innerWidth < 768;
  const getData = async (id, TYPE = "ALL") => {
    setSelectedLogId(id);
    const { response } = await getBetsFromAccountLogs(
      selectedLogId ? selectedLogId : id
    );
    let response_ = response;
    let betResponseDTOList = response_.betResponseDTOList;
    let betResponseDTOList_ = [];
    betResponseDTOList.forEach((item) => {
      if (item.back_rate && item.event_type == "Casino") {
        item.type = "back";
        item.rate = item.back_rate;
      } else if (item.lay_rate && item.event_type == "Casino") {
        item.type = "lay";
        item.rate = item.lay_rate;
      }
      if (
        item.back_run &&
        (item.event_type == "Cricket" ||
          item.event_type == "Soccer" ||
          item.event_type == "Tennis")
      ) {
        item.type = "back";
        item.rate = item.back_run;
      } else if (
        item.lay_run &&
        (item.event_type == "Cricket" ||
          item.event_type == "Soccer" ||
          item.event_type == "Tennis")
      ) {
        item.type = "lay";
        item.rate = item.lay_run;
      }
      betResponseDTOList_.push(item);
    });
    response_.betResponseDTOList = betResponseDTOList_;

    if (TYPE == "ALL") {
      response_ = response;
    }
    if (TYPE == "BACK") {
      response_.betResponseDTOList = response_.betResponseDTOList.filter(
        (item) => item.type == "back"
      );
    }
    if (TYPE == "LAY") {
      response_.betResponseDTOList = response_.betResponseDTOList.filter(
        (item) => item.type == "lay"
      );
    }
    setLogData(response_);
  };

  const getAccountStatement = async () => {
    let payload = {
      startDate: new Date(moment(startDate).subtract("M", 7)).getTime(),
      endDate: new Date(endDate).getTime(),
      type: type,
    };
    const {
      response: { coinHistory },
    } = await getCoinHistoryAPI(payload);
    setNoOfPages(Math.ceil(coinHistory.length / rowsPerPage));
    setCurrentPageData(coinHistory.slice(0, rowsPerPage)?.reverse());

    const reversedData = [];
    for (let i = coinHistory.length - 1; i >= 0; i--) {
      reversedData.push(coinHistory[i]);
    }
    console.log(reversedData);
    setData(reversedData);
  };
  const search = async (e) => {
    if (e.target.value == "") return getAccountStatement();
    setData(
      searchSubstringInArrayObjects(
        data,
        ["comment", "debitInfo", "creditInfo", "updatedBalance"],
        e.target.value
      )
    );
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    getAccountStatement();
  };
  useEffect(() => {
    getAccountStatement();
  }, [type, startDate, endDate]);
  useEffect(() => {
    setCurrentPageData(
      data.slice(
        pageNumber * rowsPerPage,
        pageNumber * rowsPerPage + rowsPerPage
      )
    );
  }, [pageNumber, selectedType, rowsPerPage]);
  return (
    <div className="container">
      <Box
        mt="5"
        p={{ base: 0, md: 2 }}
        bg={{ base: "transparent", md: "#383838" }}
        textColor="#A6A6A6"
      >
        <Box
          w="100%"
          display="flex"
          alignItems="center"
          justifyContent={{
            base: "space-between",
            md: "flex-start",
          }}
        >
          <Box
            flexDirection="column"
            display="flex"
            justifyContent="center"
            alignItems="center"
            my="2"
            width={"100%"}
          >
            <Flex width="100%" alignItems="center">
              <CustomInput
                placeholder="Select Start Date"
                type="date"
                bg="transparent"
                className="form-control"
                _placeholder={{ color: "gray" }}
                _focus={{ outline: "none" }}
                onChange={(e) => setStartDate(e.target.value)}
                value={startDate}
                containerStyle={{
                  width: "50%",
                  marginRight: "10px",
                }}
                textColor="#AAA391"
                size="md"
                borderRadius="5"
              />
              <CustomInput
                className="form-control"
                placeholder="Select Start Date"
                type="date"
                bg="transparent"
                borderRadius="5"
                _placeholder={{ color: "gray" }}
                _focus={{ outline: "none" }}
                onChange={(e) => setEndDate(e.target.value)}
                value={endDate}
                textColor="#AAA391"
                containerStyle={{
                  width: "50%",
                }}
                size="md"
              />
            </Flex>

            <CustomSelect
              borderRadius="5"
              textColor="#AAA391"
              className="form-control"
              containerStyle={{
                margin: 5,
                width: isMobileView ? "100%" : "40rem",
              }}
              size="md"
              borderColor="#AAA391"
              onChange={(e) => setType(e.target.value)}
              options={[
                {
                  label: "All",
                  value: "ALL",
                },
                {
                  label: "Deposit/Withdrawal Report",
                  value: "BALANCE_REPORT",
                },
                {
                  label: "Game Report",
                  value: "GAME_REPORT",
                },
              ]}
            />
            <CustomSelect
              borderRadius="5"
              textColor="#AAA391"
              className="form-control"
              containerStyle={{
                margin: 5,
                width: isMobileView ? "100%" : "40rem",
              }}
              value={rowsPerPage}
              size="md"
              borderColor="#AAA391"
              onChange={(e) => setRowsPerPage(e.target.value)}
              options={[
                {
                  label: "10",
                  value: 10,
                },
                {
                  label: "25",
                  value: 25,
                },
                {
                  label: "50",
                  value: 50,
                },
                {
                  label: "100",
                  value: 100,
                },
              ]}
            />

            <Button
              onClick={handleSubmit}
              style={{ width: "100%" }}
              className="btn btn-primary"
            >
              Submit
            </Button>
          </Box>
        </Box>

        <CustomTable
          columns={[
            {
              name: "PR Balance",
              id: "previousBalance",
              element: (row) => (
                <Td borderRight="1px solid gray">
                  <Text color="black">
                    {parseInt(row.previousBalance)?.toFixed(2)}
                  </Text>
                </Td>
              ),
            },
            {
              name: "Won",
              id: "credit",
              element: (row) => (
                <Td borderRight="1px solid gray">
                  <Text
                    style={{ color: row?.credit ? "darkyellow" : "black" }}
                  >
                    {row.credit ? row?.credit : "—"}
                  </Text>
                </Td>
              ),
            },
            {
              name: "Lost",
              id: "debit",
              element: (row) => (
                <Td borderRight="1px solid gray">
                  <Text style={{ color: row?.debit ? "tomato" : "black" }}>
                    {row.debit ? row?.debit : "—"}
                  </Text>
                </Td>
              ),
            },

            {
              name: "Balance",
              id: "updatedBalance",
              element: (row) => (
                <Td borderRight="1px solid gray">
                  <Text color="black">
                    {parseInt(row.updatedBalance)?.toFixed(2)}
                  </Text>
                </Td>
              ),
            },
            {
              name: "Remark",
              id: "comment",
              element: (row) => (
                <Td borderRight="1px solid gray" w="auto" display="flex">
                  <Text
                    bg={row.type == "GAME_REPORT" ? buttonColors : ""}
                    cursor="pointer"
                    p="2px 5px"
                    borderRadius="5px"
                    color={
                      row.type == "GAME_REPORT"
                        ? "black !important"
                        : "black "
                    }
                  >
                    {row.description}
                  </Text>
                </Td>
              ),
            },
            {
              name: "Date",
              id: "createdAt",
              element: (row) => (
                <Td borderRight="1px solid gray">
                  <Text color="black">
                    {moment(row.createdAt + "Z").format("DD/MM/YYYY hh:mm")}
                  </Text>
                </Td>
              ),
            },
          ]}
          rows={currentPageData}
          showPagination={false}
          totalPages_={0}
          rowsPerPage_={currentPageData.length}
          currentPage_={pageNumber}
          headerBg="#363636"
        />
        <ul className="pagination justify-content-center mt-2 snipcss-ZlA51">
          <li
            onClick={() => {
              if (pageNumber > 0) {
                setPageNumber(0);
              }
            }}
            className="page-item "
          >
            <a tabIndex={-1} className="page-link">
              &lt;&lt;
            </a>
          </li>
          <li
            onClick={() => {
              if (pageNumber > 0) {
                setPageNumber(pageNumber - 1);
              }
            }}
            className="page-item "
          >
            <a tabIndex={-1} className="page-link">
              &lt;
            </a>
          </li>
          {Array.from({ length: noOfPages }, (_, i) => i + 1).map(
            (item, index) => (
              <li
                className={`page-item ${index == pageNumber ? "active" : ""}`}
              >
                <a tabIndex={0} className="page-link">
                  {index + 1}
                </a>
              </li>
            )
          )}
          <li
            onClick={() => {
              if (pageNumber < noOfPages - 1) {
                setPageNumber(pageNumber + 1);
              }
            }}
            className="page-item "
          >
            <a tabIndex={-1} className="page-link">
              &gt;
            </a>
          </li>
          <li
            onClick={() => {
              if (pageNumber < noOfPages - 1) {
                setPageNumber(noOfPages - 1);
              }
            }}
            className="page-item "
          >
            <a tabIndex={-1} className="page-link">
              &gt;&gt;
            </a>
          </li>
        </ul>

        {/*   <Modal
            size={"6xl"}
            onClose={() => {
              setIsModalOpen(false);
            }}
            isOpen={isModalOpen}
            motionPreset="slideInBottom"
            scrollBehavior="inside"
          >
            <ModalOverlay />
            <ModalContent marginTop={10} bg={"rgb(51,51,51)"}>
              <ModalCloseButton />
              <ModalBody p="2" bg={"#2E3439"} borderRadius={"5px"}>
                <div
                  id="__BVID__2570___BV_modal_content_"
                  tabIndex={-1}
                  className="modal-content snipcss-z5ed3"
                >
                  <header
                    id="__BVID__2570___BV_modal_header_"
                    className="modal-header"
                  >
                    <h5
                      id="__BVID__2570___BV_modal_title_"
                      className="modal-title"
                    >
                      Result
                    </h5>
                    <button
                      onClick={() => setIsModalOpen(false)}
                      type="button"
                      aria-label="Close"
                      className="close"
                    >
                      ×
                    </button>
                  </header>
                  <div id="__BVID__2570___BV_modal_body_" className="modal-body">
                    <div className="mt-1">{logData?.header}</div>
                    <div className="mt-1">
                      <div className="row">
                        <div className="col-6">Winner: {logData?.winner}</div>
                        <div className="col-6 text-right">
                          Game Time:
                          <span>
                            {moment(logData?.eventDateTime).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="mt-2">
                        <div className="custom-control custom-radio custom-control-inline">
                          <input
                            type="radio"
                            id="soda-all"
                            name="example"
                            onClick={() => getData(selectedLogId, "ALL")}
                            defaultValue="all"
                            defaultChecked={selectedType == "ALL" ? true : false}
                            className="custom-control-input"
                          />
                          <label
                            htmlFor="soda-all"
                            className="custom-control-label"
                          >
                            All
                          </label>
                        </div>
                        <div className="custom-control custom-radio custom-control-inline">
                          <input
                            type="radio"
                            id="soda-back"
                            name="example"
                            defaultValue="back"
                            onClick={() => getData(selectedLogId, "BACK")}
                            className="custom-control-input"
                            defaultChecked={selectedType == "BACK" ? true : false}
                          />
                          <label
                            htmlFor="soda-back"
                            className="custom-control-label"
                          >
                            Back
                          </label>
                        </div>
                        <div className="custom-control custom-radio custom-control-inline">
                          <input
                            type="radio"
                            id="soda-lay"
                            name="example"
                            onClick={() => getData(selectedLogId, "LAY")}
                            defaultValue="lay"
                            className="custom-control-input"
                            defaultChecked={selectedType == "LAY" ? true : false}
                          />
                          <label
                            htmlFor="soda-lay"
                            className="custom-control-label"
                          >
                            Lay
                          </label>
                        </div>
  
                        <div className="custom-control-inline total-soda">
                          <div>
                            Total Bets:
                            <span className="text-success mr-2">
                              {logData?.totalBet}
                            </span>
                            Total Win:
                            <span
                              className={
                                logData?.totalWin <= 0
                                  ? "text-danger"
                                  : "text-success"
                              }
                            >
                              {logData?.totalWin}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="table-responsive report-table-modal mt-2 report-table">
                        <table className="table">
                          <thead>
                            <tr>
                              <th className="bet-nation">
                                <div>Nation</div>
                              </th>
                              <th className="text-right bet-user-rate">
                                <div>Rate</div>
                              </th>
                              <th className="text-right bet-user-rate">
                                <div>Bhav</div>
                              </th>
                              <th className="text-right bet-amount">
                                <div>Amount</div>
                              </th>
                              <th className="text-right bet-amount">
                                <div>Win</div>
                              </th>
                              <th className="bet-date">
                                <div>Date</div>
                              </th>
                              <th className="bet-ip">
                                <div>Ip Address</div>
                              </th>
                              <th className="bet-remark">
                                <div>Browser Details</div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {logData?.betResponseDTOList?.map((log) => (
                              <tr
                                className={` ${
                                  log.type == "lay" ? "lay-border" : "back-border"
                                }`}
                              >
                                <td className="bet-nation">
                                  <div>
                                    <div className="d-inline-block vm custom-control custom-checkbox">
                                      <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        defaultValue={0}
                                        id="__BVID__2616"
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor="__BVID__2616"
                                      />
                                    </div>
                                    <span className="d-inline">
                                      {log?.runner_name}
                                    </span>
                                  </div>
                                </td>
                                <td className="text-right bet-user-rate">
                                  <div>{log?.rate}</div>
                                </td>
                                <td className="text-right bet-user-rate">
                                  <div>
                                    {log?.back_rate
                                      ? log.back_rate
                                      : log.lay_rate
                                      ? log.lay_rate
                                      : "-"}
                                  </div>
                                </td>
                                <td className="text-right bet-amount">
                                  <div>{log.amount}</div>
                                </td>
                                <td className="text-right bet-amount">
                                  <div>
                                    {log.loss ? `-${log.loss}` : log.profit}
                                  </div>
                                </td>
                                <td className="bet-date">
                                  <div>
                                    {moment(log.created_date + "Z").format(
                                      "DD/MM/YYYY hh:mm"
                                    )}
                                  </div>
                                </td>
                                <td className="bet-ip">
                                  <div>{log.ipAddress}</div>
                                </td>
                                <td className="bet-remark">
                                  <Popover preventOverflow={true}>
                                    <PopoverTrigger>
                                      <Text
                                        _hover={{ cursor: "pointer" }}
                                        color="blue.500"
                                      >
                                        <u title="Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/112.0.0.0 Safari/537.36">
                                          Detail
                                        </u>
                                      </Text>
                                    </PopoverTrigger>
                                    <PopoverContent bg="gray">
                                      {log?.browserDetails}
                                    </PopoverContent>
                                  </Popover>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </ModalBody>
            </ModalContent>
          </Modal> */}
      </Box>
    </div>
  );
}
